/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - Sidebar.tsx
 */

import classnames from 'classnames';
import Gate       from 'components/Gate';
import React      from 'react';
import Section    from './Section';

import broadcastIcon   from 'assets/images/icons/sections/campaigns.svg';
import propertiesIcon  from 'assets/images/icons/sections/properties.svg';
import productsIcon    from 'assets/images/icons/sections/products.svg';
import maintenanceIcon from 'assets/images/icons/sections/maintenance.svg';
import contractsIcon   from 'assets/images/icons/sections/contracts.svg';
import plannerIcon     from 'assets/images/icons/sections/planner.svg';
import actorsIcon      from 'assets/images/icons/sections/users.svg';
import settingsIcon    from 'assets/images/icons/sections/settings.svg';
import networksIcon    from 'assets/images/icons/sections/groups.svg';
import weatherIcon     from 'assets/images/icons/sections/weather.svg';
import newsIcon        from 'assets/images/icons/sections/news.svg';


import './Sidebar.scss';
import Capability      from 'library/Capability';
import SubSection      from './SubSection';

/**
 * @return {JSX.Element}
 * @constructor
 */
const Sidebar = () => {
  return (
    <section id="sidebar">
      <Gate capabilities={ [
        Capability.campaignsView,
        Capability.networksEdit,
        Capability.devTools,
      ] }>
        { () => (
          <div className="sidebar__menu-items-group">
            <Section label="broadcast"
                     path={ '/broadcast' }
                     capabilities={ [
                       Capability.campaignsView,
                     ] }
                     highlight="--light-blue"
                     icon={ broadcastIcon }
            />
            <Section label="properties"
                     path="/properties"
                     capabilities={ [
                       `${ Capability.propertiesView }!${ Capability.propertiesEdit }`,
                     ] }
                     highlight="--danger"
                     icon={ propertiesIcon }
            />
            <Section label="networks"
                     path="/networks"
                     capabilities={ [ Capability.networksEdit ] }
                     highlight="--light-blue"
                     icon={ networksIcon }
            />
            <SubSection capabilities={ [
              Capability.devTools,
            ] }
                        highlight={ '--light-blue' }>
              <Section label="networks-monitoring"
                       path="/networks-monitoring"
                       capabilities={ [ Capability.networksMaintenance ] }
                       highlight="--light-blue"
                       icon={ maintenanceIcon }
              />
              <Section label="broadcast-maintenance"
                       path="/broadcast-maintenance"
                       capabilities={ [ Capability.devTools ] }
                       highlight="--light-blue"
              />
            </SubSection>
          </div>
        ) }
      </Gate>
      <Gate capabilities={ [ Capability.propertiesView ] }>
        { () => (
          <div className="sidebar__menu-items-group">
            <Section
              label="properties"
              path="/properties"
              capabilities={ [ Capability.propertiesView ] }
              highlight="--danger"
              icon={ propertiesIcon }
            />
            <Section
              label="products"
              path="/products"
              capabilities={ [ Capability.productCategoriesEdit ] }
              highlight="--danger"
              icon={ productsIcon }
            />
            <SubSection capabilities={ [
              Capability.contractsManage,
              Capability.productsView,
              Capability.pricelistsEdit,
              Capability.brandsEdit,
              Capability.propertiesTypesEdit,
              Capability.screenTypesEdit,
              Capability.propertiesTagsEdit,
              Capability.mobileProductsEdit,
              Capability.propertiesInventoriesEdit,
            ] }
                        highlight={ '--danger' }>
              <Section
                label="networks"
                path="/properties-networks"
                capabilities={ [ Capability.contractsManage ] }
                highlight="--danger"
              />
              <Section
                label="fill-rates"
                path="/fill-rates"
                capabilities={ [ Capability.productsView ] }
                highlight="--danger"
                // icon={ networksIcon }
              />
              <Section
                label="pricelists"
                path="/pricelists"
                capabilities={ [ Capability.pricelistsEdit ] }
                highlight="--danger"
                // icon={ pricelistsIcon }
              />
              <Section
                label="brands"
                path="/brands"
                capabilities={ [ Capability.brandsEdit ] }
                highlight="--danger"
                // icon={ brandsIcon }
              />
              <Section
                label="property-types"
                path="/property-types"
                capabilities={ [ Capability.propertiesTypesEdit ] }
                highlight="--danger"
              />
              <Section
                label="screen-types"
                path="/screen-types"
                capabilities={ [ Capability.screenTypesEdit ] }
                highlight="--danger"
              />
              <Section
                label="tags"
                path="/property-tags"
                capabilities={ [ Capability.propertiesTagsEdit ] }
                highlight="--danger"
              />
              <Section
                label="mobile-products"
                path="/mobile-products"
                capabilities={ [ Capability.mobileProductsEdit ] }
                highlight="--yellow"
              />
              <Section
                id="inventories-maintenance"
                label="inventories-maintenance"
                path="/inventories"
                capabilities={ [ Capability.propertiesInventoriesEdit ] }
                highlight="--danger"
                // icon={ maintenanceIcon }
              />
            </SubSection>
          </div>
        ) }
      </Gate>
      <Gate capabilities={ [ Capability.datasetsEdit ] }>
        <div className="sidebar__menu-items-group">
          <Section label="demo-templates"
                   path="demographics"
                   capabilities={ [ Capability.datasetsEdit ] }
                   highlight="--light-blue"/>
          <Section label="datasets"
                   path="datasets"
                   capabilities={ [ Capability.datasetsEdit ] }
                   highlight="--light-blue"/>
        </div>
      </Gate>
      <Gate capabilities={ [ Capability.contractsView, Capability.toolsPlanning, Capability.advertisersEdit ] }>
        { () => (
          <div className="sidebar__menu-items-group">
            <Section
              label="contracts"
              path="/contracts"
              capabilities={ [ Capability.contractsView ] }
              highlight="--light-blue"
              icon={ contractsIcon }
            />
            <Section
              label="planning"
              path="/campaign-planner"
              capabilities={ [ Capability.toolsPlanning ] }
              highlight="--light-blue"
              icon={ plannerIcon }
            />

            <SubSection capabilities={ [ Capability.advertisersEdit ] }
                        highlight={ '--light-blue' }>
              <Section
                label="advertisers"
                path="/advertisers"
                capabilities={ [ Capability.advertisersEdit ] }
                highlight="--light-blue"
                icon={ broadcastIcon }
              />
            </SubSection>
          </div>
        ) }
      </Gate>
      <Gate capabilities={ [
        Capability.dynamicsWeatherEdit,
        Capability.dynamicsNewsEdit,
      ] }>
        { () => (
          <div className="sidebar__menu-items-group">
            <Section label="weather-dynamic"
                     path={ '/weather-dynamic' }
                     capabilities={ [
                       Capability.dynamicsWeatherEdit,
                     ] }
                     highlight="--success"
                     icon={ weatherIcon }
            />
            <Section label="news-dynamic"
                     path={ '/news-dynamic' }
                     capabilities={ [
                       Capability.dynamicsNewsEdit,
                     ] }
                     highlight="--success"
                     icon={ newsIcon }
            />
          </div>
        ) }
      </Gate>
      <Gate capabilities={ [ 'actors.edit' ] }>
        { () => (
          <div className="sidebar__menu-items-group">
            <Section
              label="actors"
              path="/actors"
              capabilities={ [ 'actors.edit' ] }
              highlight="--warning"
              icon={ actorsIcon }
            />
            <Section
              label="settings"
              path="/settings"
              capabilities={ [
                'headlines.edit',
                'contents.review',
                'roles.edit',
                'access-token.edit',
                'network.edit',
                'locations.edit',
                'formats.edit',
                'chores.broadsign',
                'properties.markets',
                'traffic.sources',
              ] }
              highlight="--warning"
              icon={ settingsIcon }
            />
          </div>
        ) }
      </Gate>
      { process.env.NODE_ENV === 'development' && (
        <Section
          label="dev"
          path="/dev"
          capabilities={ [
            Capability.devTools,
          ] }
          highlight="--light-blue"
          icon={ settingsIcon }
        />
      ) }
      <footer>
        <a href="https://neo-ooh.com"
           target="_blank"
           rel="noreferrer"
           className={ classnames('neo-logo') }
           title="Neo-website">&nbsp;</a>
      </footer>
    </section>
  );
};

export default Sidebar;
