/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - Capability.ts
 */

const enum Capability {
  // Actors
  actorsEdit                     = 'actors.edit',
  actorsCreate                   = 'actors.create',
  actorsDelete                   = 'actors.delete',

  /**
   * Allows user to update and manage the Authentication and signup token of accessible users.
   */
  actorsAuth                     = 'actors.auth',

  /**
   * Allows user to browse Connect under the account of an accessible user.
   */
  actorsImpersonate              = 'actors.impersonate',

  // Roles
  rolesEdit                      = 'roles.edit',

  // Libraries
  librariesEdit                  = 'libraries.edit',
  librariesCreate                = 'libraries.create',
  librariesDestroy               = 'libraries.destroy',

  campaignsView                  = 'campaigns.view',
  campaignsEdit                  = 'campaigns.edit',

  /**
   * Allows access to campaign performances
   */
  campaignsPerformances          = 'campaigns.performances',

  // Contents
  contentsEdit                   = 'contents.edit',
  contentsDynamic                = 'contents.dynamic',
  contentsHtmlPackage            = 'contents.html-package',

  /**
   * Allows user to schedule stuff in campaigns
   */
  contentsSchedule               = 'contents.schedule',

  /**
   * Allows user to approve schedules in campaign., pre-approve contents in library, and editing schedules after they have been locked
   */
  contentsReview                 = 'contents.review',

  /**
   * Allows user to create bundles
   */
  schedulesBundles               = 'schedules.bundles',

  /**
   * Allow assigning tags to contents
   */
  contentsTags                   = 'contents.tags',

  /**
   * Allow assigning tags to schedules
   */
  schedulesTags                  = 'schedules.tags',

  /**
   * Allow assigning tags to campaigns
   */
  campaignsTags                  = 'campaigns.tags',

  // FormatsField
  formatsEdit                    = 'formats.edit',
  formatsCropFramesEdit          = 'formats.crop-frames.edit',

  // Headlines
  headlinesEdit                  = 'headlines.edit',

  // FormatsField
  locationsEdit                  = 'locations.edit',

  // Terms Of Services
  tosUpdate                      = 'tos.update',

  // Tests
  tests                          = 'test.capability',

  // Contracts
  /**
   * Gives read-only access to contracts associated with the user
   */
  contractsView                  = 'contracts.view',

  /**
   * Allows editing contracts associated with the user
   */
  contractsEdit                  = 'contracts.edit',

  /**
   * Gives read-only access to all contracts
   */
  contractsViewAll               = 'contracts.view-all',

  /**
   * Allows editing all contracts
   */
  contractsManage                = 'contracts.manage',

  burstsRequest                  = 'bursts.request',
  burstsQuality                  = 'bursts.quality',

  // Networks
  /**
   * Allows to create and edit broadcasters connections
   */
  networksEdit                   = 'networks.edit',

  /**
   * Allows to create and edit broadcasters connections
   */
  networksConnections            = 'networks.connections',

  /**
   * Allows to force refresh networks
   */
  networksRefresh                = 'networks.refresh',

  /**
   * Gives access to maintenance resources for networks
   */
  networksMaintenance            = 'networks.maintenance',

  // Dymamics
  dynamicsWeatherPull            = 'dynamics.weather.pull',
  dynamicsWeatherEdit            = 'dynamics.weather.edit',
  dynamicsNewsPull               = 'dynamics.news.pull',
  dynamicsNewsEdit               = 'dynamics.news.edit',

  // Documents Generation
  documentsGeneration            = 'documents.generation',

  // Access Tokens
  accessTokenEdit                = 'access-token.edit',

  // Broadsign administration
  // TODO: Rename this capability to `broadcasters.broadsign`
  choresBroadsign                = 'chores.broadsign',

  /**
   * Allow user to create, edit and remove properties in its accessible hierarchy
   */
  propertiesEdit                 = 'properties.edit',

  /**
   * Allow user to change markets and cities in them
   */
  propertiesMarkets              = 'properties.markets',

  /**
   * Allow user to setup traffic sources
   */
  trafficSources                 = 'traffic.sources',

  /**
   * Allow user to refresh the current day's traffic snapshot
   */
  trafficRefresh                 = 'traffic.refresh',

  /**
   * Allow user to setup and edit contract products and products
   */
  toolsPlanning                  = 'tools.planning',

  /**
   * Allow user to send products list made using the planning to Inventories
   */
  odooContracts                  = 'odoo.contracts',

  /**
   * Allow to request an export of impressions, like for specific display unit
   */
  impressionsExport              = 'impressions.export',

  /**
   * Allows the user to access other users plans in the planner
   */
  planningFullaccess             = 'planning.fullaccess',

  /**
   * Allows the user to edit tags
   */
  tagsEdit                       = 'tags.edit',

  /**
   * Allows the user to edit loop configurations
   */
  loopsEdit                      = 'loops.edit',

  /**
   * Allows creating and editing broadcast tags
   */
  broadcastTags                  = 'broadcast.tags',

  /**
   * Allows editing broadcast settings
   */
  broadcastSettings              = 'broadcast.settings',

  /**
   * Allows access to various tools/info for developers
   */
  devTools                       = 'dev.tools',

  /**
   * Allows editing advertisers
   */
  advertisersEdit                = 'advertisers.edit',

  /**
   * Allow managing inventories services for Connect
   */
  inventoriesEdit                = 'inventories.edit',

  /*
    |----------------------------------------------------------------------
    | Properties
    |----------------------------------------------------------------------
    */

  /**
   * Gives access to basic information on properties
   * * Address
   * * Opening hours
   * * Website
   * * Description
   * * Non-demographic fields
   */
  propertiesView                 = 'properties.view',

  /**
   * Allows creating and archiving properties
   */
  propertiesCreate               = 'properties.create',

  /**
   * Allows exporting properties data
   */
  propertiesExport               = 'properties.export',

  /**
   * Allows editing properties address
   */
  propertiesAddressEdit          = 'properties.address.edit',

  /**
   * Allows editing properties opening hours
   */
  propertiesOpeningHoursEdit     = 'properties.opening-hours.edit',

  /**
   * Allows editing properties basic information
   * * Website
   * * Description
   * * Non-demographic fields
   */
  propertiesInfosEdit            = 'properties.infos.edit',

  /**
   * Gives access to properties pictures
   */
  propertiesPicturesView         = 'properties.pictures.view',

  /**
   * Allows adding and editing properties pictures
   */
  propertiesPicturesEdit         = 'properties.pictures.edit',

  /**
   * Gives access to properties contacts
   */
  propertiesContactsView         = 'properties.contacts.view',

  /**
   * Allows adding and editing properties contacts
   */
  propertiesContactsEdit         = 'properties.contacts.edit',

  /**
   * Gives access to properties tags
   */
  propertiesTagsView             = 'properties.tags.view',

  /**
   * Allows assigning tags to properties
   */
  propertiesTagsEdit             = 'properties.tags.edit',

  /**
   * Allows creating new properties tags
   */
  propertiesTagsCreate           = 'properties.tags.create',

  /**
   * Gives access to properties demographic values
   */
  propertiesDemographicsView     = 'properties.demographics.view',

  /**
   * Allows uploading demographic values
   */
  propertiesDemographicsEdit     = 'properties.demographics.edit',

  /**
   * Gives access to properties traffic
   */
  propertiesTrafficView          = 'properties.traffic.view',

  /**
   * Allows updating properties traffic values
   */
  propertiesTrafficFill          = 'properties.traffic.fill',

  /**
   * Allows editing properties traffic settings
   */
  propertiesTrafficManage        = 'properties.traffic.manage',

  /**
   * Gives access to properties tenants directory
   */
  propertiesTenantsView          = 'properties.tenants.view',

  /**
   * Allows updating properties tenants
   */
  propertiesTenantsEdit          = 'properties.tenants.edit',

  /**
   * Gives access to properties pricelist
   */
  propertiesPricelistView        = 'properties.pricelist.view',

  /**
   * Allows updating properties pricelist
   */
  propertiesPricelistAssign      = 'properties.pricelist.assign',

  /**
   * Gives access to properties and products unavailabilities
   */
  propertiesUnavailabilitiesView = 'properties.unavailabilities.view',

  /**
   * Allows adding and updating properties and products unavailabilities
   */
  propertiesUnavailabilitiesEdit = 'properties.unavailabilities.edit',

  /**
   * Gives read access to properties and products inventories configurations
   */
  propertiesInventoriesView      = 'properties.inventories.view',

  /**
   * Allows configuring inventories connections for properties and products
   */
  propertiesInventoriesEdit      = 'properties.inventories.edit',

  /**
   * Allows creating and editing property types
   */
  propertiesTypesEdit            = 'properties.types.edit',

  /**
   * Allows editing inventory resources restrictions
   */
  propertiesRestrictionsEdit     = 'properties.restrictions.edit',

  /**
   * Allows creating and editing property types
   */
  screenTypesEdit                = 'screens.types.edit',


  /*
  |----------------------------------------------------------------------
  | Products
  |----------------------------------------------------------------------
  */

  /**
   * Gives access to properties products
   */
  productsView                   = 'products.view',

  /**
   * Allows updating general information about products
   */
  productsEdit                   = 'products.edit',

  /**
   * Allows updating products attachments
   */
  productsAttachmentsEdit        = 'products.attachments.edit',

  /**
   * Gives access to products impressions
   */
  productsImpressionsView        = 'products.impressions.view',

  /**
   * Allows updating products impressions models
   */
  productsImpressionsEdit        = 'products.impressions.edit',

  /**
   * Gives access to products locations
   */
  productsLocationsView          = 'products.locations.view',

  /**
   * Allows editing products locations
   */
  productsLocationsEdit          = 'products.locations.edit',

  /**
   * Allows editing mobile products
   */
  mobileProductsEdit             = 'mobile.products.edit',


  /*
  |----------------------------------------------------------------------
  | Product Categories
  |----------------------------------------------------------------------
  */

  /**
   * Gives access to product categories
   *
   * Product categories uses the same capabilities for edition as products.
   * e.g. To allow editing impressions models of product categories, use the product-equivalent capability.
   */
  productCategoriesEdit          = 'product-categories.edit',


  /*
  |----------------------------------------------------------------------
  | Pricelists
  |----------------------------------------------------------------------
  */

  /**
   * Allows creating and updating pricelists
   */
  pricelistsEdit                 = 'pricelists.edit',

  /*
  |----------------------------------------------------------------------
  | Brands
  |----------------------------------------------------------------------
  */

  /**
   * Gives access to the brands list
   */
  brandsView                     = 'brands.view',

  /**
   * Allows adding and editing brands
   */
  brandsEdit                     = 'brands.edit',

  /**
   * Give access to brands POIs list/map
   */
  brandsPoiView                  = 'brands.poi.view',

  /**
   * Allows editing brands POIs
   */
  brandsPoiEdit                  = 'brands.poi.edit',

  /*
  |----------------------------------------------------------------------
  | Fields
  |----------------------------------------------------------------------
  */

  /**
   * Allows creating, editing and assigning fields
   */
  fieldsEdit                     = 'fields.edit',

  /**
   * Allows creating and updating fields using demographic values
   */
  fieldsDemographicsEdit         = 'fields.demographics.edit',

  /*
    |----------------------------------------------------------------------
    | Demographics
    |----------------------------------------------------------------------
    */

  datasetsEdit                   = 'datasets.edit',

  /*
    |----------------------------------------------------------------------
    | Assets
    |----------------------------------------------------------------------
    */

  assetsView                     = 'assets.view',
  assetsActions                  = 'assets.actions',
  assetsEdit                     = 'assets.edit',
  assetsProviders                = 'assets.providers',
}

export default Capability;
