/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - Actor.ts
 */

import Collection                                                                      from 'library/Collection';
import Request, { HTTPMethod }                                                         from 'library/Request';
import routes                                                                          from 'library/routes';
import { DateTime }                                                                    from 'luxon';
import { ActorLogo, Capability, ICapability, IRole, ITag, Location, Phone, Role, Tag } from 'models';
import { Model }                                                                       from 'library/Model';
import API                                                                             from 'library/Model/API';
import { ModelPersistAction, RouteAttributesDescriptor }                               from 'library/Model/types';
import { IProperty }                                                                   from './interfaces/IProperty';
import { ActorType }                                                                   from './enums/ActorType';
import { preparePathForSingleModelAction, preparePathParameters }                      from 'library/Model/utils';
import { makeRoute }                                                                   from 'library/modelsUtils';
import { makeRequest }                                                                 from 'library/Request/Request';
import { ActorClosure }                                                                from './interfaces/ActorClosure';
import CapabilitySlug                                                                  from 'library/Capability';

export interface IActor {
  id: number,
  name: string,
  email: string,
  password: string | null,
  locale: 'fr' | 'en',
  is_group: boolean,
  is_property: boolean,
  is_contract: boolean,
  is_locked: boolean,
  locked_by: number,
  limited_access: boolean,
  last_login_at: DateTime | null,
  last_activity_at: DateTime | null,
  created_at: DateTime,
  updated_at: DateTime,

  type: ActorType;

  phone_id: number | null,
  phone: Phone | null,

  two_fa_method: 'email' | 'phone',
  signup_token: Record<string, any> | null,
  registration_sent: boolean,
  is_registered: boolean,

  logo: ActorLogo | null

  parent_id: number | null,
  parent: Actor | null,
  group: Actor | null,
  children: Collection<Actor>,
  direct_children: Collection<Actor>,
  ancestors_ids: Collection<number>,

  additional_accesses: Collection<Actor>,

  roles: Collection<Role>,
  standalone_capabilities: Collection<Capability>,
  capabilities: Collection<Capability>,

  locations: Collection<Location>,
  own_locations: Collection<Location>,

  tags: Collection<Tag>


  property: IProperty,
}

class ActorModel extends Model<IActor> {
  _slug = 'actor';

  invalidateRoutes = [ '/v1/actors', '/v1/actors/{id}' ];

  basePath = '/v1/actors';

  attributesTypes: { [attr in keyof IActor]?: (sourceAttr: any) => IActor[attr] } = {
    ancestors_ids          : (d: ActorClosure[]) => Collection.from(d).pluck('ancestor_id'),
    phone                  : Model.make(Phone),
    children               : Collection.ofType(Actor).make,
    direct_children        : Collection.ofType(Actor).make,
    logo                   : Model.make(ActorLogo),
    locations              : Collection.ofType(Location).make,
    own_locations          : Collection.ofType(Location).make,
    roles                  : Collection.ofType(Role).make,
    standalone_capabilities: Collection.ofType(Capability).make,
    capabilities           : Collection.ofType(Capability).make,
    additional_accesses    : Collection.ofType(Actor).make,
    tags                   : Collection.ofType(Tag).make,
    last_login_at          : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    last_activity_at       : (d) => DateTime.fromISO(d),
    updated_at             : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    created_at             : (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  key = 'id' as const;

  routesAttributes: { [attr in ModelPersistAction]: RouteAttributesDescriptor<IActor> } = {
    create: [
      'is_group',
      'name',
      'email',
      'locale',
      'parent_id',
      'roles',
      'make_library',
    ],
    save  : [
      'name',
      'email',
      'password',
      'locale',
      'is_locked',
      'parent_id',
      'limited_access',
      'two_fa_method',
    ],
  };
}

export class Actor extends ActorModel implements IActor {
  id!: number;

  name!: string;

  email!: string;

  password!: string | null;

  locale!: 'en' | 'fr';

  is_group!: boolean;

  is_property!: boolean;

  is_locked!: boolean;

  is_contract!: boolean;

  locked_by!: number;

  logo!: ActorLogo | null;

  limited_access!: boolean;

  last_login_at!: DateTime | null;

  last_activity_at!: DateTime | null;

  created_at!: DateTime;

  updated_at!: DateTime;

  phone_id!: number | null;

  phone!: Phone | null;

  two_fa_method!: 'email' | 'phone';

  signup_token!: Record<string, any> | null;

  registration_sent!: boolean;

  is_registered!: boolean;

  parent_id!: number | null;

  parent!: Actor | null;

  group!: Actor | null;

  children!: Collection<Actor>;

  direct_children!: Collection<Actor>;

  additional_accesses!: Collection<Actor>;

  roles!: Collection<Role>;

  standalone_capabilities!: Collection<Capability>;

  capabilities!: Collection<Capability>;

  locations!: Collection<Location>;

  own_locations!: Collection<Location>;

  tags!: Collection<Tag>;

  property!: IProperty;

  type!: ActorType;

  ancestors_ids!: Collection<number>;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  static async byId(_: null, clientIds: number[]) {
    return API.byId(Actor, clientIds);
  }

  isUser() {
    return this.type === ActorType.User;
  }

  isGroup() {
    return this.type === ActorType.Group || this.type === ActorType.Property || this.type === ActorType.Contract;
  }

  /*
   |--------------------------------------------------------------------------
   | Actions
   |--------------------------------------------------------------------------
   */

  savePhone(phone: {
    country: string,
    number: string
  }) {
    const path   = preparePathForSingleModelAction(this.basePath, '/phone');
    const params = preparePathParameters(path, this);
    const route  = makeRoute(path, phone.number.length === 0 ? HTTPMethod.delete : HTTPMethod.put);

    return makeRequest<Phone>(route, params, {
      phone        : phone.number,
      phone_country: phone.country,
    }).then(({ data }) => {
      if (typeof data === 'object') {
        this.setAttributes({ phone: data, phone_id: data.id });
      } else {
        this.phone_id = null;
        this.phone    = null;
      }
    });
  }

  syncAdditionalAccesses(actors: number[]) {
    const path   = preparePathForSingleModelAction(this.basePath, '/accesses');
    const params = preparePathParameters(path, this);
    const route  = makeRoute(path, HTTPMethod.post);

    return makeRequest<IRole[]>(route, params, {
      actors: actors,
    }).then(() => {
      return this.invalidate();
    });
  }

  syncRoles(roles: number[]) {
    const path   = preparePathForSingleModelAction(this.basePath, '/roles');
    const params = preparePathParameters(path, this);
    const route  = makeRoute(path, HTTPMethod.put);

    return makeRequest<IRole[]>(route, params, {
      roles: roles,
    }).then(({ data }) => {
      return this.invalidate();
    });
  }

  syncCapabilities(capabilities: number[]) {
    const path   = preparePathForSingleModelAction(this.basePath, '/capabilities');
    const params = preparePathParameters(path, this);
    const route  = makeRoute(path, HTTPMethod.put);

    return makeRequest<ICapability[]>(route, params, {
      capabilities: capabilities,
    }).then(({ data }) => {
      return this.invalidate();
    });
  }

  syncLocations(locations: number[]) {
    return Request.make(routes.actors.locations.sync, { id: this.getKey() }, { locations })
                  .then(({ data }) => {
                    this.setAttributes(data);
                    return data;
                  });
  }

  syncTags(tags: number[]) {
    const path   = preparePathForSingleModelAction(this.basePath, '/tags');
    const params = preparePathParameters(path, this);
    const route  = makeRoute(path, HTTPMethod.put);

    return makeRequest<ITag[]>(route, params, {
      tags: tags,
    }).then(({ data }) => {
      // @ts-ignore
      this.setAttributes({ tags: data });
      return this;
    });

  }

  resendWelcomeEmail() {
    return Request.make(routes.actors.resendWelcomeEmail, { id: this.getKey() });
  }

  recycleTwoFa() {
    const path   = preparePathForSingleModelAction(this.basePath, '/two-fa/recycle');
    const params = preparePathParameters(path, this);
    const route  = makeRoute(path, HTTPMethod.post);

    return makeRequest(route, params);
  }

  validateTwoFa() {
    const path   = preparePathForSingleModelAction(this.basePath, '/two-fa/validate');
    const params = preparePathParameters(path, this);
    const route  = makeRoute(path, HTTPMethod.post);

    return makeRequest(route, params);
  }

  /**
   * @returns {string}
   */
  getLinkToProfile() {
    if (this.is_group) {
      return `/groups/${ this.getKey() }`;
    }

    return `/users/${ this.getKey() }`;
  }


  /**
   * Tell if the user has at least one of the passed capability.ies
   * This method can take an array of capabilities, in this case the function will return true on the first valid capability.
   * It is possible to mark a capability as ignored if another one is present 'a and not b' separating them with an exclamation point : 'foo!bar' means 'Has the `foo` capability but doesn't have the `bar` capability.
   * @param caps {string|string[]}
   * @return {boolean}
   */
  hasCapability(caps: string | string[]) {
    const capabilities = Array.isArray(caps) ? caps : [ caps ];

    const capSlugs = new Set(this.capabilities?.pluck('slug') ?? []);

    if (capabilities.length === 0) {
      return true;
    }

    for (const cap of capabilities) {
      const [ capability, ...exclusionCapabilities ] = cap.split('!');

      if (!capSlugs.has(capability as CapabilitySlug)) {
        // The user doesn't has the capability, stop checking this item here.
        continue;
      }

      let valid = true;

      // make sure any exclusion capability are correctly met
      for (const exclusionCap of exclusionCapabilities) {
        if (capSlugs.has(exclusionCap as CapabilitySlug)) {
          // this capability is present but we don't want it, mark this item as invalid and exit this loop.
          valid = false;
          break;
        }
      }

      // If all exclusion capability passed successfully, then we can stop here with a success, otherwise, we continue the loop
      if (valid) {
        return true;
      }
    }

    return false;
  }
}
